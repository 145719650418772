import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import elvenHunterThumbnail from '../assets/images/dolls/ElvenHunter/ElvenHunter-thumbnail.png';
import cyberHackerThumbnail from '../assets/images/dolls/CyberHacker/CyberHacker-thumbnail.png';
import darkMageThumbnail from '../assets/images/dolls/DarkMage/DarkMage-thumbnail.png';
import phoenixFighterThumbnail from '../assets/images/dolls/PhoenixFighter/PhoenixFighter-thumbnail.png';
import twilekScoundrelThumbnail from '../assets/images/dolls/TwilekScoundrel/TwilekScoundrel-thumbnail.png';
import demonSlayerRedThumbnail from '../assets/images/dolls/DemonSlayerRed/DemonSlayerRed-thumbnail.png';
import TwoBThumbnail from '../assets/images/dolls/2B/2B-thumbnail.png'
import watermark from '../assets/images/AI-Watermark.png';
import '../App.css';

const DollSelectionPage = ({ fetchUserData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        await fetchUserData(); // Fetch user data but no need to assign to 'data' if not used
        setLoading(false); // Assuming you set loading based on data fetch success
      } catch (error) {
        setError('Failed to load user data.');
        setLoading(false);
      }
    };

    loadUserData();
  }, [navigate, fetchUserData]);

  useEffect(() => {
    const handleBackButton = () => {
      if (location.pathname === '/select-doll') {
        navigate('/mode-select');
      }
    };

    window.addEventListener('popstate', handleBackButton);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [location, navigate]);

  useEffect(() => {
    const loadUserData = async () => {
      try {
        // First, check if data exists in sessionStorage
        const storedPledgeData = sessionStorage.getItem('pledgeData');
        if (storedPledgeData) {
          // Use cached data
          setLoading(false); // Stop loading since we have valid data
          return;
        }

        // Fetch user data from the API if not in sessionStorage
        const data = await fetchUserData();
        if (!data || !data.pledgeData || data.pledgeData.currentlyEntitledDollars < 1) {
          console.log('Insufficient pledge, redirecting to upgrade...');
          navigate('/upgrade');
        } else {
          // Save data to sessionStorage and stop loading
          sessionStorage.setItem('pledgeData', JSON.stringify(data.pledgeData));
          setLoading(false);
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('Failed to load user data.');
        setLoading(false);
      }
    };

    loadUserData();
  }, [navigate, fetchUserData]);

  // Only render the page content if it's done loading and there is no error
  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  const handleSelect2B = () => {
    navigate('/doll/2B');
  };

  const handleSelectDemonSlayerRed = () => {
    navigate('/doll/demonslayerred');
  };

  const handleSelectTwilekScoundrel = () => {
    navigate('/doll/twilekscoundrel');
  };

  const handleSelectPhoenixFighter = () => {
    navigate('/doll/phoenixfighter');
  };

  const handleSelectDarkMage = () => {
    navigate('/doll/darkmage');
  };

  const handleSelectCyberHacker = () => {
    navigate('/doll/cyberhacker');
  };

  const handleSelectElvenHunter = () => {
    navigate('/doll/elvenhunter');
  };

  return (
    <div className="doll-selection-page">
      <h1>Select Your Doll</h1>

      <div className="doll-grid">
        <div className="doll-item" onClick={handleSelect2B}>
          <img src={TwoBThumbnail} alt="2B" className="thumbnail" />
          <p>2B</p>
        </div>

        <div className="doll-item" onClick={handleSelectDemonSlayerRed}>
          <img src={demonSlayerRedThumbnail} alt="Demon Slayer Red" className="thumbnail" />
          <p>Demon Slayer Red</p>
        </div>

        <div className="doll-item" onClick={handleSelectTwilekScoundrel}>
          <img src={twilekScoundrelThumbnail} alt="Twilek Scoundrel" className="thumbnail" />
          <p>Twilek Scoundrel</p>
        </div>

        <div className="doll-item" onClick={handleSelectPhoenixFighter}>
          <img src={phoenixFighterThumbnail} alt="Phoenix Fighter" className="thumbnail" />
          <p>Phoenix Fighter</p>
        </div>

        <div className="doll-item" onClick={handleSelectDarkMage}>
          <img src={darkMageThumbnail} alt="Dark Mage" className="thumbnail" />
          <p>Dark Mage</p>
        </div>

        <div className="doll-item" onClick={handleSelectCyberHacker}>
          <img src={cyberHackerThumbnail} alt="Cyber Hacker" className="thumbnail" />
          <p>Cyber Hacker</p>
        </div>

        <div className="doll-item" onClick={handleSelectElvenHunter}>
          <img src={elvenHunterThumbnail} alt="Elven Hunter" className="thumbnail" />
          <p>Elven Hunter</p>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="footer">
        <div>
          <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
            <img src={watermark} alt="Support me on Patreon" className="select-watermark" />
          </a>
          <p>
            <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
              Support me on Patreon by clicking here
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default DollSelectionPage;
