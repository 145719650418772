import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DiceRoller from '../../components/DiceRoller.jsx';
import DialogueContainer from '../../components/DialogueContainer.js';
import ChoicesContainer from '../../components/ChoicesContainer.js';
import HPComponent from '../../components/HPComponent.jsx';

//CSS
import '../../App.css';
import './ElvenHunterStory.css';
//IMAGES
import battlePose from '../../assets/game/ElvenHunter/scene01/BattlePose.png';
import deeperForestBackground from '../../assets/game/ElvenHunter/battle01/deeperForestBackground.jpg';
//Audio Controls
import volMaxIcon from '../../assets/icons/vol-max.svg';
import volOffIcon from '../../assets/icons/vol-off.svg';
//MUSIC
import BattleBegins from '../../assets/music/ElvenHunter/BattleBegins01.mp3';

function ElvenHunterBattle01() {
  const navigate = useNavigate();
  const [state, setState] = useState({
    sceneStage: 'initial',
    narrative: "The Elven Huntress, her eyes sharp with resolve, knows there's only one path forward—battle. The tension is thick, and her stance leaves no room for negotiation. What will you do?",
    battleOutcome: '',
    rollResult: null,
  });
  const [isMuted, setIsMuted] = useState(false);
  const backgroundMusicRef = useRef(null);

  //Dice Roller Diabled Check
  const [diceRollerDisabled, setDiceRollerDisabled] = useState(false);

  //First Roll In Battle - Initiative
  const handleBattleRoll = (playerRoll) => {
    const elarisRoll = Math.floor(Math.random() * 20) + 1; // Roll for Elaris
    let outcomeText = '';

    if (playerRoll > elarisRoll) {
      outcomeText = `You rolled ${playerRoll}, and Elaris rolled ${elarisRoll}. You go first!`;
      setState(prevState => ({ ...prevState, battleOutcome: outcomeText, initiativeWinner: 'player' }));
    } else if (elarisRoll > playerRoll) {
      outcomeText = `You rolled ${playerRoll}, and Elaris rolled ${elarisRoll}. Elaris goes first!`;
      setState(prevState => ({ ...prevState, battleOutcome: outcomeText, initiativeWinner: 'elaris' }));
    } else {
      outcomeText = `You both rolled ${playerRoll}. It’s a tie, but you go first by default.`;
      setState(prevState => ({ ...prevState, battleOutcome: outcomeText, initiativeWinner: 'player' }));
    }
  };


  // Player Attacks
  const handleAttackRoll01 = (result) => {
    const dc = 14;
    let outcomeText = '';

    if (result === 20) {
      outcomeText = 'Critical Success! Your overhead strike is devastating!';
    } else if (result >= dc) {
      outcomeText = 'Success! You land a solid hit.';
    } else if (result === 1) {
      outcomeText = 'Critical Failure! You miss entirely, leaving yourself open.';
    } else {
      outcomeText = 'Failure! Your attack is deflected.';
    }



    setState(prevState => ({
      ...prevState,
      battleOutcome: outcomeText,
      sceneStage: 'outcome' // Keep the stage as 'battleRoll01' until the player clicks to continue
    }));
  };



  const handleAttackRoll02 = () => {
    const dc = 16;
    const roll = Math.floor(Math.random() * 20) + 1;
    let outcomeText = '';

    if (roll >= dc) {
      outcomeText = 'Success! Your improvised attack catches her off guard!';
    } else {
      outcomeText = 'Failure! She anticipates your move and counters!';
    }

    setState((prevState) => ({
      ...prevState,
      battleOutcome: outcomeText,
      sceneStage: 'outcome02',
    }));
  };

  const handleAttackRoll03 = () => {
    const dc = 17;
    const roll = Math.floor(Math.random() * 20) + 1;
    let outcomeText = '';

    if (roll >= dc) {
      outcomeText = 'Success! Your goofy maneuver surprises her, landing a hit!';
    } else {
      outcomeText = 'Failure! She sees through your wild antics!';
    }

    setState((prevState) => ({
      ...prevState,
      battleOutcome: outcomeText,
      sceneStage: 'outcome03',
    }));
  };

  // Elaris Attacks
  const handleElarisAttack = () => {
    const attackOptions = [
      { name: 'Swift Slash', dc: 14, damage: 1, description: 'Elaris quickly slashes at you with her blade.' },
      { name: 'Piercing Thrust', dc: 16, damage: 1, description: 'Elaris lunges forward, attempting to pierce your defenses.' },
      { name: 'Shadow Step', dc: 17, damage: 1, description: 'Elaris disappears into the shadows, reappearing behind you with a deadly strike.' },
      { name: 'Arcane Slash', dc: 18, damage: 1, description: 'Elaris channels magic into her blade, enhancing her attack with elemental power.' },
      { name: 'Blinding Strike', dc: 19, damage: 1, description: 'Elaris throws a flash of light into your eyes, following up with a disorienting slash.' },
      { name: 'Vengeful Fury', dc: 20, damage: 2, description: 'Elaris unleashes a devastating combination of strikes, aiming to overwhelm you.' }
    ];

    const selectedAttack = attackOptions[Math.floor(Math.random() * attackOptions.length)];
    const elarisRoll = Math.floor(Math.random() * 20) + 1; // Elaris rolls a D20
    let outcomeText = '';

    if (elarisRoll === 1) {
      // Critical failure scenario
      outcomeText = 'Elaris slips, losing her balance and composure! You gain an advantage in the fight.';
      setState(prevState => ({
        ...prevState,
        elarisClothing: prevState.elarisClothing - 1, // Reduce her clothing or armor state
        elarisOutcome: outcomeText,
      }));
    } else if (elarisRoll >= selectedAttack.dc) {
      // Elaris succeeds
      const newPlayerHP = state.playerHP - selectedAttack.damage;
      outcomeText = `${selectedAttack.name}! ${selectedAttack.description}`;
      setState(prevState => ({
        ...prevState,
        playerHP: newPlayerHP,
        elarisOutcome: outcomeText,
      }));
      if (selectedAttack.name === 'Vengeful Fury') {
        setState(prevState => ({
          ...prevState,
          playerHP: newPlayerHP - 1, // Reduce an additional heart for critical attack
        }));
      }
    } else {
      // Elaris fails
      outcomeText = 'Elaris attempts to attack but misses!';
      setState(prevState => ({
        ...prevState,
        elarisOutcome: outcomeText,
      }));
    }

    // Check for victory or death after Elaris' attack
    if (state.playerHP <= 0) {
      navigate('/story/elvenhunter/deathScene');
    } else if (state.elarisClothing <= 0) {
      navigate('/story/elvenhunter/victoryScene');
    }
  };


  //Turn Loop Logic
  const nextTurn = () => {
    if (state.sceneStage === 'playerAttack') {
      handleElarisAttack();
      setState(prevState => ({ ...prevState, sceneStage: 'elarisAttack' }));
    } else if (state.sceneStage === 'elarisAttack') {
      setState(prevState => ({ ...prevState, sceneStage: 'playerAttack' }));
    }
  };


  const handleCharismaRoll = (result) => {
    const dc = 18;
    let outcomeText = '';
    if (result >= 20) {
      outcomeText = 'Critical Success! She hesitates, lowering her weapon.';
    } else if (result >= dc) {
      outcomeText = 'Success! She pauses, considering your words.';
    } else {
      outcomeText = 'Failure! She sees through your attempt and attacks!';
    }
    setState((prevState) => ({ ...prevState, battleOutcome: outcomeText, sceneStage: 'outcome' }));
  };

  useEffect(() => {
    if (backgroundMusicRef.current) {
      backgroundMusicRef.current.volume = isMuted ? 0 : 0.1;
    }
  }, [isMuted]);

  useEffect(() => {
    const audio = new Audio(BattleBegins);
    audio.loop = true;

    if (!isMuted) {
      audio.play().catch((error) => {
        console.error('Failed to play audio:', error);
      });
    }

    return () => {
      audio.pause();
      audio.currentTime = 0; // Reset playback to start
    };
  }, [isMuted]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
  };

  return (
    <div className="game-canvas-container">
      <div className="game-canvas" style={{ backgroundImage: `url(${deeperForestBackground})` }}>
        {/* Audio Elements */}
        <audio ref={backgroundMusicRef} src={BattleBegins} loop autoPlay />
        {/* Audio Controls */}
        <div className="audio-controls-container">
          <div className="audio-controls">
            <div className="audio-control">
              <img src={isMuted ? volOffIcon : volMaxIcon} alt="Toggle Mute" className="icon-white" onClick={toggleMute} />
              <span className="audio-button-label">On/Off</span>
            </div>
          </div>
        </div>
        {/* Initial Choices */}
        {state.sceneStage === 'initial' && (
          <DialogueContainer text={state.narrative}>

            <ChoicesContainer
              choices={[
                { text: 'Meet her head-on in battle!', action: () => setState(prevState => ({ ...prevState, sceneStage: 'battle', narrative: 'The battle begins!' })) },
                { text: 'Attempt to talk your way out of it.', action: () => setState(prevState => ({ ...prevState, sceneStage: 'charismaCheck', narrative: 'You attempt to appeal to her better nature. Roll a Charisma Check!' })) },
              ]}
            />

          </DialogueContainer>

        )}


        {/* Battle Scene01 - Initiate */}
        {state.sceneStage === 'battle' && (
          <DialogueContainer text={"The battle begins, roll initiative!"}>
            <DiceRoller onRoll={handleBattleRoll} disabled={state.battleOutcome !== ''} /> {/* Disable if battle outcome exists */}
            {state.battleOutcome && (
              <>
                <p className="result-text">{state.battleOutcome}</p>
                <button
                  onClick={() => {
                    if (state.initiativeWinner === 'player') {
                      setState(prevState => ({ ...prevState, sceneStage: 'playerAttack' }));
                    } else {
                      setState(prevState => ({ ...prevState, sceneStage: 'elarisAttack' }));
                    }
                  }}
                  className="next-button"
                >
                  Continue
                </button>
              </>
            )}
            <HPComponent maxHP={3} onDeath={() => navigate('/story/elvenhunter/deathScene')} />
          </DialogueContainer>
        )}
        {/* Player and Elaris Attack Scenes */}
        {state.sceneStage === 'playerAttack' && (
          <DialogueContainer text="You have the initiative! Choose your method of attack:">
            <ChoicesContainer
              choices={[
                { text: 'Raising your blade high, you launch a powerful overhead strike.', action: () => setState({ sceneStage: 'battleRoll01' }) },
                { text: 'You grab a nearby branch, using it as an improvised weapon.', action: () => setState({ sceneStage: 'battleRoll02' }) },
                { text: 'You start dancing erratically, throwing in a wild punch.', action: () => setState({ sceneStage: 'battleRoll03' }) },
              ]}
            />
            <HPComponent maxHP={3} onDeath={() => navigate('/story/elvenhunter/deathScene')} />
          </DialogueContainer>
        )}

        {state.sceneStage === 'battleRoll01' && (
          <DialogueContainer text={"You attempt your attack!"}>
            <DiceRoller
              onRoll={handleAttackRoll01}
              disabled={diceRollerDisabled}
            />
            {state.battleOutcome && (
              <>
                <p className="result-text">{state.battleOutcome}</p>
                <button
                  onClick={() => {
                    setDiceRollerDisabled(false); // Re-enable the dice roller for Elaris's turn
                    setState(prevState => ({ ...prevState, sceneStage: 'elarisAttack' })); // Move to Elaris's attack
                  }}
                  className="next-button"
                >
                  Elaris's Turn
                </button>
              </>
            )}
          </DialogueContainer>
        )}


        {state.sceneStage === 'battleRoll02' && (
          <DiceRoller onRoll={handleAttackRoll02} />
        )}

        {state.sceneStage === 'battleRoll03' && (
          <DiceRoller onRoll={handleAttackRoll03} />
        )}



        {state.sceneStage === 'elarisAttack' && (
          <>
            <DialogueContainer text={state.elarisOutcome || "Elaris prepares to attack!"}>
              <button
                onClick={() => {
                  handleElarisAttack(); // Perform Elaris' attack
                }}
                className="next-button"
              >
                Continue
              </button>
            </DialogueContainer>
            {/* HP Component to show player's current HP */}
            <HPComponent
              maxHP={3} // Set to the player's starting HP
              loseHP={state.playerHP} // Update dynamically based on Elaris' attacks
              onDeath={() => navigate('/story/elvenhunter/deathScene')} // Navigate if HP reaches 0
            />
          </>
        )}
        {/* Battle Scene02 - First attack */}
        {state.sceneStage === 'battle02' && (
          <DialogueContainer text={state.narrative}>
            <DiceRoller onRoll={handleBattleRoll} />
            <HPComponent maxHP={3} onDeath={() => navigate('/story/elvenhunter/deathScene')} />
          </DialogueContainer>
        )}

        {/* Charisma Check Scene */}
        {state.sceneStage === 'charismaCheck' && (
          <DialogueContainer text={state.narrative}>
            <DiceRoller onRoll={handleCharismaRoll} />
          </DialogueContainer>
        )}

        {/* Display the Outcome Text */}
        {state.sceneStage === 'outcome' && (
          <DialogueContainer text={state.battleOutcome}>
            <button onClick={() => navigate('/story/elvenhunter/outcome')} className="next-button">
              Continue
            </button>
          </DialogueContainer>
        )}

        {/* Display the Battle Pose Image */}
        <img src={battlePose} alt="Jumping Battle Pose" className="elven-jumping-battle-pose" />
      </div>
    </div>
  );
}

export default ElvenHunterBattle01;
