import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';
import watermark from '../assets/images/AI-Watermark.png'; // Import AI watermark

const HomePage = () => {
  const navigate = useNavigate();

  // Handle Patreon login
  const handleLogin = () => {
    window.location.href = 'https://api.ai-pinups.io/auth/patreon';
  };

  // Handle Free Mode (no Patreon login required)
  const handleFreeMode = () => {
    navigate('/free-mode-selection');  // Navigate to free mode content
  };

  // Handle disagree (redirect to Google)
  const handleDisagree = () => {
    window.location.href = 'https://www.google.com';
  };

  return (
<div className="home-page">
  {/* Display AI watermark logo */}
  <img
    src={watermark}
    alt="AI Pinups Watermark"
    className="watermark-image"
  />

  {/* Welcome message */}
  <h1>Welcome to the AI Pinups Game!</h1>
  <p>You must be 18 to enter this site.</p>

  {/* Patreon login, OR text, and free mode buttons */}
  <div className="button-group">
    <button
      onClick={handleLogin}
      style={{ backgroundColor: '#f96854', color: '#fff', marginBottom: '10px' }}
    >
      Login with Patreon
    </button>

    {/* OR text between buttons */}
    <p style={{ margin: '10px 0', fontWeight: 'bold' }}>OR</p>

    <button
      onClick={handleFreeMode}
      style={{ backgroundColor: '#00bfff', color: '#fff', marginBottom: '10px', marginLeft: '10px' }}
    >
      Enter Free Mode
    </button>
  </div>

  {/* Disagree button */}
  <button
    onClick={handleDisagree}
    style={{ backgroundColor: '#ccc', color: '#000', marginTop: '20px' }}
  >
    Disagree
  </button>

        {/* Footer Links */}
        <div className="footer-links" style={{ marginTop: '40px', fontSize: '14px' }}>
        <a href="/privacy.html" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        {' | '}
        <a href="/ToS.html" target="_blank" rel="noopener noreferrer">
          Terms of Service
        </a>
        {' | '}
        <a href="mailto: support@ai-pinups.io" target="_blank" rel="noopener noreferrer">
          Contact Support
        </a>
      </div>
</div>
  );
};

export default HomePage;
