// src/pages/ElvenHunterPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import watermarkImage from '../../assets/images/AI-Watermark.png';
import '../../App.css';

const DraggableImage = ({ id, src, x, y, scale, onDragEnd }) => {
  const [image] = useImage(src);
  return (
    <KonvaImage
      image={image}
      x={x}
      y={y}
      scaleX={scale}
      scaleY={scale}
      draggable
      onDragEnd={(e) => onDragEnd(id, e.target.x(), e.target.y())}
    />
  );
};

const ElvenHunterDoll = () => {
  const [outfits, setOutfits] = useState([]);
  const [elvenHunterMain] = useImage(require('../../assets/images/dolls/ElvenHunter/ElvenHunterMainFree.jpg'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageTopOffset, setImageTopOffset] = useState(0);

  useEffect(() => {
    const updateScale = () => {
      const windowHeight = window.innerHeight;
      const originalHeight = 1080; // Original height of the doll image
      const newScale = windowHeight / originalHeight;

      setScale(newScale);
      setImageHeight(originalHeight * newScale);
      setImageTopOffset(20 * newScale);
    };

    updateScale();
    window.addEventListener('resize', updateScale);

    return () => window.removeEventListener('resize', updateScale);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the drawer is open and the click/touch event is outside the drawer
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        // Check if the click/touch is on the drawer toggle button
        if (event.target.classList.contains('drawer-toggle')) {
          return; // Do nothing if the drawer toggle button is clicked
        }
        setDrawerOpen(false);
      }
    };
  
    // Add event listeners for both mouse and touch events
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      // Clean up event listeners
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [drawerRef]);

  const outfitOptions = [
    { id: 'elven-shaal', name: 'Elven Shaal', src: require('../../assets/images/dolls/ElvenHunter/outfits/elven-shaal.png') },
    //{ id: 'green-bikini-top', name: 'Green Bikini Top', src: require('../../assets/images/dolls/ElvenHunter/outfits/green-bikini-top.png') },
    //{ id: 'green-bikini-bottom', name: 'Green Bikini Bottom', src: require('../../assets/images/dolls/ElvenHunter/outfits/green-bikini-bottom.png') },
  ];

  const handleAddOutfit = (outfit) => {
    if (!outfits.find(o => o.id === outfit.id)) {
      const canvasWidth = window.innerWidth;
      const canvasHeight = window.innerHeight;
      const outfitCenterX = (canvasWidth - 100 * scale) / 2;
      const outfitCenterY = (canvasHeight - 100 * scale) / 2;

      setOutfits([...outfits, { id: outfit.id, src: outfit.src, x: outfitCenterX, y: outfitCenterY }]);
    }
  };

  const handleRemoveOutfit = (id) => {
    setOutfits(outfits.filter(outfit => outfit.id !== id));
  };

  const handleBringToFront = (id) => {
    const outfit = outfits.find(outfit => outfit.id === id);
    setOutfits(outfits.filter(outfit => outfit.id !== id).concat(outfit));
  };

  const handleDragEnd = (id, x, y) => {
    setOutfits(outfits.map(outfit => (outfit.id === id ? { ...outfit, x, y } : outfit)));
  };

  return (
<div className="doll-page">
  <button className="drawer-toggle" onClick={() => setDrawerOpen(!drawerOpen)}>
    ☰
  </button>
  <div ref={drawerRef} className={`drawer ${drawerOpen ? 'open' : ''}`}>
    <div className="drawer-content">
      <button onClick={() => window.location.href = '/select-doll-free'}>Return to Doll Selection</button>
      {outfitOptions.map((outfit) => (
        <div key={outfit.id} className="menu-item">
          <span>{outfit.name}</span>
          {!outfits.find(o => o.id === outfit.id) && (
            <button onClick={() => handleAddOutfit(outfit)}>Add</button>
          )}
          {outfits.find(o => o.id === outfit.id) && (
            <>
              <button onClick={() => handleRemoveOutfit(outfit.id)}>Remove</button>
              <button onClick={() => handleBringToFront(outfit.id)}>Bring to Front</button>
            </>
          )}
        </div>
      ))}
    </div>
  </div>
      <Stage width={window.innerWidth} height={window.innerHeight}>
        <Layer>
          <KonvaImage
            image={elvenHunterMain}
            width={576 * scale}
            height={1080 * scale}
            x={(window.innerWidth - 576 * scale) / 2}
            y={imageTopOffset}
          />
          {outfits.map((outfit) => (
            <DraggableImage
              key={outfit.id}
              id={outfit.id}
              src={outfit.src}
              x={outfit.x}
              y={outfit.y}
              scale={scale}
              onDragEnd={handleDragEnd}
            />
          ))}
        </Layer>
      </Stage>
      <div
        className="doll-page-watermark-container"
        style={{
          position: 'absolute',
          left: '50%',
          top: `${imageTopOffset + imageHeight - 5 - 100}px`,
          transform: 'translateX(-50%)',
          zIndex: 2,
        }}
      >
        <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
          <img src={watermarkImage} alt="Watermark" className="doll-page-watermark" />
        </a>
      </div>
    </div>
  );
};

export default ElvenHunterDoll;
