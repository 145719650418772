import React from 'react';
import { BrowserRouter as Router, Route, Routes,Navigate } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';  // Use HTML5 backend
import Analytics from './Analytics';


import HomePage from './pages/HomePage';
import Dashboard from './pages/Dashboard';
import ModeSelectionPage from './pages/ModeSelectionPage';
//FREE MODE
import FreeModeSelectionPage from './pages/FreeModeSelection';
import DollSelectionFreeMode from './pages/DollSelectionFreeMode';
import ElvenHunterDollFree from './pages/dolls/ElvenHunterDollFree';
import CyberHackerDollFree from './pages/dolls/CyberHackerDollFree';
import TwoBDollFree from './pages/dolls/2BDollFree';
import TwoBDollTest from './pages/dolls/2BDollDump'

import DeathScene from "./components/deathScene";
import DollSelectionPage from './pages/DollSelectionPage';
// Dolls
import ElvenHunterDoll from './pages/dolls/ElvenHunterDoll';
import CyberHackerDoll from './pages/dolls/CyberHackerDoll';
import DarkMageDoll from './pages/dolls/DarkMageDoll';
import PhoenixFighterDoll from './pages/dolls/PhoenixFighterDoll';
import TwilekScoundrelDoll from './pages/dolls/TwilekScoundrelDoll';
import DemonSlayerRedDoll from './pages/dolls/DemonSlayerRedDoll';
import TwoBDoll from './pages/dolls/2BDoll';


//Story
import ElvenHunterIntro from './pages/storymode/ElvenHunterIntro'
import StorySelectionPage from './pages/StorySelectionPage';
import BattleTemplate from './pages/BattleTemplate';
import ElvenHunterStoryScene01 from './pages/ElvenHunter/ElvenHunterStoryScene01';
import ElvenHunterBattle01 from './pages/ElvenHunter/ElvenHunterBattle01';
import ElvenHunterPass01 from './pages/ElvenHunter/ElvenHunterPass01';
import ElvenHunterChase01 from './pages/ElvenHunter/ElvenHunterChase01';
import ProtectedRoute from './components/ProtectedRoute'; // Import the ProtectedRoute component
import UpgradePage from './pages/upgradePage'; // Add this import
import fetchUserData from './utils/fetchUserData'; // Assume you have this utility for fetching user data
import './App.css';



const App = () => {
  return (
    <DndProvider backend={HTML5Backend}>  {/* Use the HTML5 backend for drag-and-drop */}
      <Router>
        <Analytics /> {/* Add Google Analytics */}
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
          <Route path="/mode-selection" element={<ModeSelectionPage />} />
          <Route path="/upgrade" element={<UpgradePage />} />
          <Route path="/dashboard" element={<Dashboard />} />

          {/* Freebies */}
          <Route path="/free-mode-selection" element={<FreeModeSelectionPage />} />
          <Route path="select-doll-free" element={<DollSelectionFreeMode />} />
          <Route path="/doll/elvenhunter-free" element={<ElvenHunterDollFree />} />
          <Route path="/doll/cyberhacker-free" element={<CyberHackerDollFree />} />
          <Route path="/doll/2B-free" element={<TwoBDollFree />} />
          <Route path="/doll/2B-test" element={<TwoBDollTest />} />

          {/* Minimum Pledge $1 - Use component instead of JSX */}
          <Route path="/select-doll" element={<ProtectedRoute minimumPledge={1}><DollSelectionPage fetchUserData={fetchUserData} /></ProtectedRoute>} />
          <Route path="/doll/elvenhunter" element={<ProtectedRoute minimumPledge={1}><ElvenHunterDoll fetchUserData={fetchUserData}  /></ProtectedRoute>} />
          <Route path="/doll/cyberhacker" element={<ProtectedRoute minimumPledge={1}><CyberHackerDoll fetchUserData={fetchUserData}  /></ProtectedRoute>} />
          <Route path="/doll/darkmage" element={<ProtectedRoute minimumPledge={1}><DarkMageDoll fetchUserData={fetchUserData}  /></ProtectedRoute>} />
          <Route path="/doll/phoenixfighter" element={<ProtectedRoute minimumPledge={1}><PhoenixFighterDoll fetchUserData={fetchUserData}  /></ProtectedRoute>} />
          <Route path="/doll/twilekscoundrel" element={<ProtectedRoute minimumPledge={1}><TwilekScoundrelDoll fetchUserData={fetchUserData} /></ProtectedRoute>} />
          <Route path="/doll/demonslayerred" element={<ProtectedRoute minimumPledge={1}><DemonSlayerRedDoll fetchUserData={fetchUserData} /></ProtectedRoute>} />
          <Route path="/doll/2B" element={<ProtectedRoute minimumPledge={1}><TwoBDoll fetchUserData={fetchUserData} /></ProtectedRoute>} />

          {/* Story Mode Test - Elven Hunter */}
          <Route path="/story/elvenhunter/intro" element={<ElvenHunterIntro />} />

          {/* Story Mode - Elven Hunter */}
          <Route path="/story-selection" element={<StorySelectionPage />} />
          <Route path="/story/elvenhunter/scene01" element={<ElvenHunterStoryScene01 />} />
          <Route path="/story/elvenhunter/battle01" element={<ElvenHunterBattle01 />} />
          <Route path="/story/elvenhunter/pass01" element={<ElvenHunterPass01 />} />
          <Route path="/story/elvenhunter/chase01" element={<ElvenHunterChase01 />} />
        </Routes>
      </Router>
    </DndProvider>
  );
};

export default App;
