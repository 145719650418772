import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ children, minimumPledge }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [pledgeData, setPledgeData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();  // Detect current route location

  useEffect(() => {
    const fetchUserData = async () => {
      try {
                const storedPledgeData = sessionStorage.getItem('pledgeData');
                if (storedPledgeData) {
                  setPledgeData(JSON.parse(storedPledgeData));
                  setIsLoading(false);
                } else {
        const response = await fetch('https://api.ai-pinups.io/api/user', {
          method: 'GET',
          credentials: 'include',
        });

        if (!response.ok) {
          throw new Error(`Failed to fetch user data: ${response.statusText}`);
        }

        const data = await response.json();
        if (data && data.pledgeData) {
          setPledgeData(data.pledgeData);
          sessionStorage.setItem('pledgeData', JSON.stringify(data.pledgeData));  // Cache the pledge data
        } else {
          throw new Error('No pledge data found.');
        }
      }
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Failed to fetch user data.');
        navigate('/upgrade'); // Redirect to upgrade if error occurs
      } finally {
        setIsLoading(false);
      }
    };

      fetchUserData();
  }, [navigate, location]);

  
  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Check if the pledge is sufficient
  if (!pledgeData || pledgeData.currentlyEntitledDollars < minimumPledge) {
    navigate('/upgrade');
    return null;
  }

  // Render the children component if everything is valid
  return children;
};

export default ProtectedRoute;
