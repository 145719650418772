import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import '../App.css'; // Import your existing App.css for styling

const Dashboard = () => {
  const [userData, setUserData] = useState(null);
  const [pledgeData, setPledgeData] = useState(null);
  const [error, setError] = useState(null);

  const fetchUserData = async () => {
    try {
      const response = await fetch('https://api.ai-pinups.io/api/user', {
        method: 'GET',
        credentials: 'include', // Ensures cookies (session) are sent
      });

      const data = await response.json();

      if (response.ok) {
        console.log('Patreon User Data:', data);
        setUserData(data.userData);  // Set user data
        setPledgeData(data.pledgeData);  // Set pledge data
      } else {
        setError(data.error || 'Failed to fetch user data');
      }
    } catch (err) {
      console.error('Error fetching user data:', err);
      setError('Failed to fetch user data');
    }
  };

  useEffect(() => {
    fetchUserData(); // Fetch the user and pledge data when the component mounts
  }, []);

  const handleLogout = () => {
    window.location.href = 'https://api.ai-pinups.io/auth/logout';
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (!userData) {
    return <div>Fetching user data...</div>;
  }

  // Safely access user data and attributes
  const userAttributes = userData?.data?.attributes || {};
  const { full_name, email, vanity, url, image_url } = userAttributes;

  // Safely access pledge data
  const { currentlyEntitledDollars, patronStatus } = pledgeData || {};

  return (
    <div className="dashboard-container">
      <div className="dashboard-content">
        <h1>Dashboard</h1>

        {/* Display user information */}
        <p><strong>Full Name:</strong> {full_name || 'N/A'}</p>
        <p><strong>Email:</strong> {email || 'N/A'}</p>
        <p><strong>Vanity URL:</strong> {vanity || 'N/A'}</p>
        <p><strong>Profile URL:</strong> <a href={url} target="_blank" rel="noopener noreferrer">{url}</a></p>
        <img src={image_url} alt="Profile" style={{ width: '150px', borderRadius: '50%' }} />

        {/* Display pledge information */}
        {pledgeData ? (
          <div>
            <h2>Pledge Information</h2>
            <p><strong>Pledge Amount:</strong> {`$${currentlyEntitledDollars}`}</p>
            <p><strong>Pledge Status:</strong> {patronStatus}</p>
          </div>
        ) : (
          <p>No pledge information available.</p>
        )}

        {/* Button Group */}
        <div className="button-group">
          <Link to="/mode-selection">
            <button className="button">Go to Mode Selection</button>
          </Link>

          <button className="button logout-button" onClick={handleLogout}>
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
