// src/pages/ModeSelectionPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../App.css';  // Import App.css for global styles

const FreeModeSelection = () => {
  const navigate = useNavigate();

  return (
    <div className="mode-selection-page">
      <h1>Select Mode</h1>
      <div className="button-group">
        <button onClick={() => navigate('/story-selection')}>Story Mode</button> {/* Navigate to Story Mode */}
        <button onClick={() => navigate('/select-doll-free')}>Dress Up Mode - Free Version</button>  {/* Navigate to Dress Up Mode */}
      </div>
    </div>
  );
};

export default FreeModeSelection;
