import React, { useState, useEffect } from 'react';
import './DiceRoller.css';

const DiceRoller = ({ onRoll, successThreshold, disabled }) => {
  const [rollResult, setRollResult] = useState(null);
  const [rolling, setRolling] = useState(false);

  const rollDice = () => {
    setRolling(true);
    const result = Math.floor(Math.random() * 20) + 1; // Roll a D20

    setTimeout(() => {
      setRolling(false);
      setRollResult(result);
      onRoll(result); // Pass the result back to the parent component

      if (result === 20) {
        // Trigger shake and fireworks effects for critical success
        const diceRoller = document.querySelector('.dice-roller');
        diceRoller.classList.add('critical-success');

        const fireworks = document.createElement('div');
        fireworks.className = 'fireworks';
        diceRoller.appendChild(fireworks);

        // Remove the classes after the animation ends to allow for re-triggering
        setTimeout(() => {
          diceRoller.classList.remove('critical-success');
          diceRoller.removeChild(fireworks);
        }, 1500); // Ensure it matches the longer animation duration
      }

      if (result === 1) {
        // Trigger shake and fireworks effects for critical fail
        const diceRoller = document.querySelector('.dice-roller');
        diceRoller.classList.add('critical-fail');

        const fireworks = document.createElement('div');
        fireworks.className = 'fireworks';
        diceRoller.appendChild(fireworks);

        setTimeout(() => {
          diceRoller.classList.remove('critical-fail');
          diceRoller.removeChild(fireworks);
        }, 1500);
      }
    }, 1000); // Simulate rolling delay
  };

  useEffect(() => {
    console.log('rollResult:', rollResult);
    console.log('Success Threshold:', successThreshold);
    console.log('Is Success:', rollResult !== null && rollResult >= successThreshold);
  }, [rollResult, successThreshold]);

  const isSuccess = rollResult !== null && rollResult >= successThreshold;
  const resultClass = rollResult !== null ? (isSuccess ? 'success' : 'failure') : '';

  return (
    <div className="dice-roller-container">
      <div className="dice-roller">
        <button
          onClick={rollDice}
          disabled={disabled || rolling}
          className={rolling ? 'shake' : ''} // Apply the shake class while rolling
        >
          Roll D20
        </button>
        {rolling ? (
          <p>Rolling...</p>
        ) : rollResult !== null && (
          <p className={`result-text ${resultClass}`}>
            Result: <span style={{ color: isSuccess ? 'green' : 'red' }}>{rollResult}</span>
          </p>
        )}
      </div>
    </div>
  );
};

export default DiceRoller;
