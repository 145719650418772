// src/pages/ElvenHunterPage.js
import React, { useState, useEffect, useRef } from 'react';
import { Stage, Layer, Image as KonvaImage } from 'react-konva';
import useImage from 'use-image';
import { useNavigate, useLocation } from 'react-router-dom';  // Import useNavigate
import watermarkImage from '../../assets/images/AI-Watermark.png';
import '../../App.css';

const DraggableImage = ({ id, src, x, y, scale, onDragEnd }) => {
  const [image] = useImage(src);
  return (
    <KonvaImage
      image={image}
      x={x}
      y={y}
      scaleX={scale}
      scaleY={scale}
      draggable
      onDragEnd={(e) => onDragEnd(id, e.target.x(), e.target.y())}
    />
  );
};



const DemonSlayerRedDoll = () => {
  const [outfits, setOutfits] = useState([]);
  const [elvenHunterMain] = useImage(require('../../assets/images/dolls/DemonSlayerRed/DemonSlayerRed-main.jpg'));
  const [swordVisible, setSwordVisible] = useState(false); // New state for sword visibility
  const [swordImage] = useImage(require('../../assets/images/dolls/DemonSlayerRed/outfits/sword-full.png')); // Sword image
  const [hairStrand] = useImage(require('../../assets/images/dolls/DemonSlayerRed/hair-strand.png')); // Hair strand image
  const [drawerOpen, setDrawerOpen] = useState(false);
  const drawerRef = useRef(null);
  const [scale, setScale] = useState(1);
  const [imageHeight, setImageHeight] = useState(0);
  const [imageTopOffset, setImageTopOffset] = useState(0);
  const location = useLocation();

  const navigate = useNavigate();  // Initialize useNavigate for routing

  useEffect(() => {
    const updateScale = () => {
      const windowHeight = window.innerHeight;
      const originalHeight = 1920; // Original height of the doll image
      const newScale = windowHeight / originalHeight;

      setScale(newScale);
      setImageHeight(originalHeight * newScale);
      setImageTopOffset(20 * newScale);
    };

    updateScale();
    window.addEventListener('resize', updateScale);

    return () => window.removeEventListener('resize', updateScale);
  }, []);

  const handleToggleSword = () => {
    setSwordVisible(!swordVisible); // Toggle sword visibility
  };

  
  useEffect(() => {
    const handleClickOutside = (event) => {
      // If the drawer is open and the click/touch event is outside the drawer
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        // Check if the click/touch is on the drawer toggle button
        if (event.target.classList.contains('drawer-toggle')) {
          return; // Do nothing if the drawer toggle button is clicked
        }
        setDrawerOpen(false);
      }
    };
  
    // Add event listeners for both mouse and touch events
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
  
    return () => {
      // Clean up event listeners
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [drawerRef]);

  useEffect(() => {
    const handleBackButton = () => {
      // Logic to handle the back button
      if (location.pathname === 'doll/demonslayerred') {
        navigate('/select-doll');
      }
    };

    window.addEventListener('popstate', handleBackButton);

    return () => {
      window.removeEventListener('popstate', handleBackButton);
    };
  }, [location, navigate]);

  const outfitOptions = [
    { id: 'red-thong', name: 'Red Thong', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/red-thong.png') },
    { id: 'wrapped-top', name: 'Wrapped Top', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/wrapped-top.png') },
    { id: 'thigh-highs', name: 'Thigh Highs', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/thigh-highs.png') },
    { id: 'arm-wraps', name: 'Arm Wraps', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/arm-wraps.png') },
    { id: 'black-skirt', name: 'Black Skirt', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/black-skirt.png') },
    { id: 'red-mini-kimono', name: 'Red Mini Kimono', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/red-mini-kimono.png') },
    { id: 'red-shoes', name: 'Red Shoes', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/red-shoes.png') },
    { id: 'mask-scarf', name: 'Mask & Scarf', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/mask-scarf.png') },
    { id: 'hat', name: 'Hat', src: require('../../assets/images/dolls/DemonSlayerRed/outfits/hat.png') },
  ];

  const handleAddOutfit = (outfit) => {
    if (!outfits.find(o => o.id === outfit.id)) {
      const canvasWidth = window.innerWidth;
      const canvasHeight = window.innerHeight;
      const outfitCenterX = (canvasWidth - 100 * scale) / 2;
      const outfitCenterY = (canvasHeight - 100 * scale) / 2;

      setOutfits([...outfits, { id: outfit.id, src: outfit.src, x: outfitCenterX, y: outfitCenterY }]);
    }
  };

  const handleRemoveOutfit = (id) => {
    setOutfits(outfits.filter(outfit => outfit.id !== id));
  };

  const handleBringToFront = (id) => {
    const outfit = outfits.find(outfit => outfit.id === id);
    setOutfits(outfits.filter(outfit => outfit.id !== id).concat(outfit));
  };

  const handleDragEnd = (id, x, y) => {
    setOutfits(outfits.map(outfit => (outfit.id === id ? { ...outfit, x, y } : outfit)));
  };

  return (
<div className="doll-page">
  <button className="drawer-toggle" onClick={() => setDrawerOpen(!drawerOpen)}>
    ☰
  </button>
  <div ref={drawerRef} className={`drawer ${drawerOpen ? 'open' : ''}`}>
    <div className="drawer-content">
      <button onClick={() => navigate('/select-doll')}>Return to Doll Selection</button>
                {/* Button to toggle the sword on/off */}
                <button onClick={handleToggleSword}>
            {swordVisible ? 'Remove Sword' : 'Add Sword'}
          </button>
      {outfitOptions.map((outfit) => (
        <div key={outfit.id} className="menu-item">
          <span>{outfit.name}</span>
          {!outfits.find(o => o.id === outfit.id) && (
            <button onClick={() => handleAddOutfit(outfit)}>Add</button>
          )}
          {outfits.find(o => o.id === outfit.id) && (
            <>
              <button onClick={() => handleRemoveOutfit(outfit.id)}>Remove</button>
              <button onClick={() => handleBringToFront(outfit.id)}>Bring to Front</button>
            </>
          )}
        </div>
      ))}
    </div>
  </div>
  <Stage width={window.innerWidth} height={window.innerHeight}>
        {/* First Layer: Display outfits and the main image */}
        <Layer>
          {/* Display the main image */}
          <KonvaImage
            image={elvenHunterMain}
            width={1024 * scale}
            height={1920 * scale}
            x={(window.innerWidth - 1024 * scale) / 2}
            y={imageTopOffset}
          />

                    {/* Display the sword as part of the "background" when it's visible */}
                    {swordVisible && (
            <KonvaImage
              image={swordImage}
              width={1024 * scale} // Sword sized the same as the main image
              height={1920 * scale}
              x={(window.innerWidth - 1024 * scale) / 2}
              y={imageTopOffset}
              listening={false} // Makes the sword "non-interactive" like a background
            />
          )}

          

          {/* Render draggable outfits */}
          {outfits.map((outfit) => (
            <DraggableImage
              key={outfit.id}
              id={outfit.id}
              src={outfit.src} // Preload image via prop
              x={outfit.x}
              y={outfit.y}
              scale={scale}
              onDragEnd={handleDragEnd}
            />
          ))}
        </Layer>

        <Layer>
          {/* Hair Strand Image (Non-draggable and always above outfits) */}
          <KonvaImage
            image={hairStrand}
            width={1024 * scale}
            height={1920 * scale}
            x={(window.innerWidth - 1024 * scale) / 2}
            y={imageTopOffset}
            listening={false}  // Make it non-interactive
          />
        </Layer>
      </Stage>
      <div
        className="doll-page-watermark-container"
        style={{
          position: 'absolute',
          left: '50%',
          top: `${imageTopOffset + imageHeight - 5 - 100}px`,
          transform: 'translateX(-50%)',
          zIndex: 2,
        }}
      >
        <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
          <img src={watermarkImage} alt="Watermark" className="doll-page-watermark" />
        </a>
      </div>
    </div>
  );
};

export default DemonSlayerRedDoll;
