// src/GoogleAnalytics.js
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    // Load Google Analytics script
    const script = document.createElement('script');
    script.src = `https://www.googletagmanager.com/gtag/js?id=G-MHSY75Z089`;
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      // Initialize Google Analytics
      window.dataLayer = window.dataLayer || [];
      function gtag() {
        window.dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-MHSY75Z089');
    };

    return () => {
      // Cleanup the script if necessary
      document.head.removeChild(script);
    };
  }, []);

  useEffect(() => {
    // Track page views on route change
    if (window.gtag) {
      window.gtag('config', 'G-MHSY75Z089', {
        page_path: location.pathname + location.search,
      });
    }
  }, [location]);

  return null; // No need to render anything
};

export default GoogleAnalytics;
