import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DialogueContainer from '../../components/DialogueContainer.js';
import ChoicesContainer from '../../components/ChoicesContainer.js';
//Images
import closeupCautious from '../../assets/game/ElvenHunter/scene01/closeupCautious01.png';
import closeupFurrowedBrow from '../../assets/game/ElvenHunter/scene01/closeupFurrowedBrow01.png';
import closeupShockedSurprise from '../../assets/game/ElvenHunter/scene01/closeupShockedSurprise01.png';
import closeupAnnoyed from '../../assets/game/ElvenHunter/scene01/closeupAnnoyed.png';
import closeupDetermined from '../../assets/game/ElvenHunter/scene01/closeupDeterminedGaze.png';
import closeupSkeptical from '../../assets/game/ElvenHunter/scene01/closeupSkeptical.png';
import elvenHunterFullBody from '../../assets/game/ElvenHunter/scene01/ElvenHunterFullBody.png';
import fightEndingScene01 from '../../assets/game/ElvenHunter/scene01/fightEndingScene01.png';
import passEndingScene01 from '../../assets/game/ElvenHunter/scene01/passEndingScene01.png';
import chaseEndingScene01 from '../../assets/game/ElvenHunter/scene01/chaseEndingScene01.png'
import forestBackground from '../../assets/game/ElvenHunter/scene01/forestbackground.jpg';
//Audio Controls
import volMaxIcon from '../../assets/icons/vol-max.svg';
//import volMinIcon from '../../assets/icons/vol-min.svg';
import volOffIcon from '../../assets/icons/vol-off.svg';
//Background Music
import elvishFantasyVocalsLoop from '../../assets/music/ElvenHunter/ElvishFantasyVocalsLoop.mp3';
import DiceRoller from '../../components/DiceRoller'; //Dice Roller

//DIALOGUE AUDIO
import narration01 from '../../assets/audio/ElvenHunter/Scene01-Narration01.mp3';

//A Scenes Audio
import elarisA00 from '../../assets/audio/ElvenHunter/Scene01-Elaris-A00.mp3';
import elarisA01Courage from '../../assets/audio/ElvenHunter/Scene01-Elaris-A01-Courage.mp3';
import elarisA01Bold from '../../assets/audio/ElvenHunter/Scene01-Elaris-A01-Bold.mp3';
import elarisA01Lost from '../../assets/audio/ElvenHunter/Scene01-Elaris-A01-Lost.mp3'
import elarisA02TalkCheap from '../../assets/audio/ElvenHunter/Scene01-Elaris-A02-TalkCheap.mp3'
import elarisA02Prepare from '../../assets/audio/ElvenHunter/Scene01-Elaris-A02-Prepare.mp3'
import elarisA02EarnFreedom from '../../assets/audio/ElvenHunter/Scene01-Elaris-A02-EarnFreedom.mp3'

//B Scenes Audio
import elarisB00 from '../../assets/audio/ElvenHunter/Scene01-Elaris-B00.mp3';
import elarisB01Peace from '../../assets/audio/ElvenHunter/Scene01-Elaris-B01-PeacfulGesture.mp3'
import elarisB01Wits from '../../assets/audio/ElvenHunter/Scene01-Elaris-B01-Wits.mp3'
import elarisB01Visitor from '../../assets/audio/ElvenHunter/Scene01-Elaris-B01-Visitor.mp3'
import elarisB02Sincerity from '../../assets/audio/ElvenHunter/Scene01-Elaris-B02-Sincerity.mp3'
import elarisB02Respect from '../../assets/audio/ElvenHunter/Scene01-Elaris-B02-Respect.mp3'
import elarisB02Weakness from '../../assets/audio/ElvenHunter/Scene01-Elaris-B02-Weakness.mp3'

//C Scenes Audio
import elarisC00 from '../../assets/audio/ElvenHunter/Scene01-Elaris-C00.mp3';
import elarisC01SilenceGame from '../../assets/audio/ElvenHunter/Scene01-Elaris-C01-SilenceGame.mp3';
import elarisC01Fool from '../../assets/audio/ElvenHunter/Scene01-Elaris-C01-Fool.mp3';
import elarisC01Fainting from '../../assets/audio/ElvenHunter/Scene01-Elaris-C01-Fainting.mp3';
import elarisC02ThinkEscape from '../../assets/audio/ElvenHunter/Scene01-Elaris-C02-ThinkEscape.mp3';
import elarisC02GraveMistake from '../../assets/audio/ElvenHunter/Scene01-Elaris-C02-GraveMistake.mp3';
import elarisC02River from '../../assets/audio/ElvenHunter/Scene01-Elaris-C02-River.mp3';

//CSS
import '../../App.css';
import './ElvenHunterStory.css';

function ElvenHunterIntro() {
  const navigate = useNavigate();  // This line defines navigate
  const [state, setState] = useState({
    sceneStage: 'initial',
    selectedChoice: null,
    showFullBody: true,
    showCloseUp: false,
    closeUpImage: null,
    elvenResponse: '',
    diceRollResult: null,
    diceCheckPassed: null,
  });

  const [showDiceRoller, setShowDiceRoller] = useState(false);
  const [diceCheckInfo, setDiceCheckInfo] = useState({ dc: 0, type: '' });

  const handleDiceRoll = (rollResult) => {
    const { dc, type } = diceCheckInfo;
    if (rollResult >= dc) {
      setState({ ...state, diceCheckPassed: true, diceRollResult: rollResult });
      handleSuccess(type); // Handle success based on type of check (Intimidate, Stealth, Deception)
    } else {
      setState({ ...state, diceCheckPassed: false, diceRollResult: rollResult });
      handleFailure(type); // Handle failure based on type of check
    }
    setShowDiceRoller(false); // Hide the dice roller after roll
  };

  const handleSuccess = (type) => {
    switch (type) {
      case 'intimidate':
        // Proceed with intimidate success outcome
        setState((prevState) => ({
          ...prevState,
          sceneStage: 'nextChoicesA01',
          selectedChoice: 'standGround',
        }));
        break;
      case 'stealth':
        // Proceed with stealth success outcome
        setState((prevState) => ({
          ...prevState,
          sceneStage: 'nextChoicesB01',
          selectedChoice: 'hide',
        }));
        break;
      case 'deception':
        // Proceed with deception success outcome
        setState((prevState) => ({
          ...prevState,
          sceneStage: 'nextChoicesC01',
          selectedChoice: 'playDead',
        }));
        break;
      default:
        break;
    }
  };

  const handleFailure = (type) => {
    switch (type) {
      case 'intimidate':
        // Intimidation failed outcome
        setState((prevState) => ({
          ...prevState,
          sceneStage: 'intimidateFail',
          selectedChoice: 'standGround',
        }));
        break;
      case 'stealth':
        // Stealth failed outcome
        setState((prevState) => ({
          ...prevState,
          sceneStage: 'stealthFail',
          selectedChoice: 'hide',
        }));
        break;
      case 'deception':
        // Deception failed outcome
        setState((prevState) => ({
          ...prevState,
          sceneStage: 'deceptionFail',
          selectedChoice: 'playDead',
        }));
        break;
      default:
        break;
    }
  };

    const handleInitialChoice1 = () => {
    setDiceCheckInfo({ dc: 15, type: 'intimidate' }); // Intimidation Check (DC 15)
    setShowDiceRoller(true);
  };

  const handleInitialChoice2 = () => {
    setDiceCheckInfo({ dc: 12, type: 'stealth' }); // Stealth Check (DC 12)
    setShowDiceRoller(true);
  };

  const handleInitialChoice3 = () => {
    setDiceCheckInfo({ dc: 18, type: 'deception' }); // Deception Check (DC 18)
    setShowDiceRoller(true);
  };

  //const [audioVolume, setAudioVolume] = useState(0.2); // Initial volume at 60%
  const [isMuted, setIsMuted] = useState(false);
  const [audio] = useState(new Audio(elvishFantasyVocalsLoop));
  const backgroundMusicRef = useRef(null);
  const narrationRef = useRef(null);
  const elarisA00Ref = useRef(null);
  const elarisB00Ref = useRef(null);
  const elarisC00Ref = useRef(null);

  //A audio
  const elarisA01CourageRef = useRef(new Audio(elarisA01Courage));
  const elarisA01BoldRef = useRef(new Audio(elarisA01Bold));
  const elarisA01LostRef = useRef(new Audio(elarisA01Lost));
  const elarisA02TalkCheapRef = useRef(new Audio(elarisA02TalkCheap));
  const elarisA02PrepareRef = useRef(new Audio(elarisA02Prepare));
  const elarisA02EarnFreedomRef = useRef(new Audio(elarisA02EarnFreedom));


  // B audio
  const elarisB01PeaceRef = useRef(new Audio(elarisB01Peace));
  const elarisB01WitsRef = useRef(new Audio(elarisB01Wits));
  const elarisB01VisitorRef = useRef(new Audio(elarisB01Visitor));
  const elarisB02SincerityRef = useRef(new Audio(elarisB02Sincerity));
  const elarisB02RespectRef = useRef(new Audio(elarisB02Respect));
  const elarisB02WeaknessRef = useRef(new Audio(elarisB02Weakness));

  // C audio
  const elarisC01SilenceGameRef = useRef(new Audio(elarisC01SilenceGame));
  const elarisC01FoolRef = useRef(new Audio(elarisC01Fool));
  const elarisC01FaintingRef = useRef(new Audio(elarisC01Fainting));
  const elarisC02ThinkEscapeRef = useRef(new Audio(elarisC02ThinkEscape));
  const elarisC02GraveMistakeRef = useRef(new Audio(elarisC02GraveMistake));
  const elarisC02RiverRef = useRef(new Audio(elarisC02River));


  useEffect(() => {
    if (isMuted) {
      backgroundMusicRef.current.volume = 0;
      narrationRef.current.volume = 0;
      elarisA00Ref.current.volume = 0;
      elarisB00Ref.current.volume = 0;
      elarisC00Ref.current.volume = 0;
    } else {
      backgroundMusicRef.current.volume = 0.1; // adjust volume as needed
      narrationRef.current.volume = 1.0;
      elarisA00Ref.current.volume = 1.0;
      elarisB00Ref.current.volume = 1.0;
      elarisC00Ref.current.volume = 1.0;
      // A audio
      elarisA01BoldRef.current.volume = 1.0;
      elarisA01CourageRef.current.volume = 1.0;
      elarisA01LostRef.current.volume = 1.0;
      elarisA02TalkCheapRef.current.volume = 1.0;
      elarisA02PrepareRef.current.volume = 1.0;
      elarisA02EarnFreedomRef.current.volume = 1.0;

      // B audio
      elarisB01PeaceRef.current.volume = 1.0;
      elarisB01WitsRef.current.volume = 1.0;
      elarisB01VisitorRef.current.volume = 1.0;
      elarisB02RespectRef.current.volume = 1.0;
      elarisB02WeaknessRef.current.volume = 1.0;
      elarisB02SincerityRef.current.volume = 1.0;

      //C audio
      elarisC01SilenceGameRef.current.volume = 1.0;
      elarisC01FoolRef.current.volume = 1.0;
      elarisC01FaintingRef.current.volume = 1.0;
      elarisC02ThinkEscapeRef.current.volume = 1.0;
      elarisC02GraveMistakeRef.current.volume = 1.0;
      elarisC02RiverRef.current.volume = 1.0;
    }
  }, [isMuted]);

  useEffect(() => {
    if (state.sceneStage === 'initial') {
      const timer = setTimeout(() => {
        narrationRef.current.play().catch((error) => {
          console.error('Failed to play narration:', error);
        });
      }, 1000); // 1-second delay

      // Cleanup function to clear the timer if the component unmounts or scene changes
      return () => clearTimeout(timer);
    }
  }, [state.sceneStage]);

  const playAudio = (audioRef) => {
    // Stop all dialogues
    narrationRef.current.pause();
    elarisA00Ref.current.pause();
    elarisB00Ref.current.pause();
    elarisC00Ref.current.pause();
    elarisA01BoldRef.current.pause();

    // A audio
    elarisA01CourageRef.current.pause();
    elarisA01LostRef.current.pause();
    elarisA02TalkCheapRef.current.pause();
    elarisA02PrepareRef.current.pause();
    elarisA02EarnFreedomRef.current.pause();

    // B audio
    elarisB01PeaceRef.current.pause();
    elarisB01WitsRef.current.pause();
    elarisB01VisitorRef.current.pause();
    elarisB02SincerityRef.current.pause();
    elarisB02RespectRef.current.pause();
    elarisB02WeaknessRef.current.pause();

    // C audio
    elarisC01SilenceGameRef.current.pause();
    elarisC01FoolRef.current.pause();
    elarisC01FaintingRef.current.pause();
    elarisC02ThinkEscapeRef.current.pause();
    elarisC02GraveMistakeRef.current.pause();
    elarisC02RiverRef.current.pause();

    // Stop other audio references if needed...

    // Reset all dialogue audio
    narrationRef.current.currentTime = 0;
    elarisA00Ref.current.currentTime = 0;
    elarisB00Ref.current.currentTime = 0;
    elarisC00Ref.current.currentTime = 0;

    // A audio
    elarisA01BoldRef.current.currentTime = 0;
    elarisA01CourageRef.current.currentTime = 0;
    elarisA01LostRef.current.currentTime = 0;
    elarisA02TalkCheapRef.current.currentTime = 0;
    elarisA02PrepareRef.current.currentTime = 0;
    elarisA02EarnFreedomRef.current.currentTime = 0;

    // B audio
    elarisB01PeaceRef.current.currentTime = 0;
    elarisB01WitsRef.current.currentTime = 0;
    elarisB01VisitorRef.current.currentTime = 0;
    elarisB02SincerityRef.current.currentTime = 0;
    elarisB02RespectRef.current.currentTime = 0;
    elarisB02WeaknessRef.current.currentTime = 0;

    // C audio
    elarisC01SilenceGameRef.current.currentTime = 0;
    elarisC01FoolRef.current.currentTime = 0;
    elarisC01FaintingRef.current.currentTime = 0;
    elarisC02ThinkEscapeRef.current.currentTime = 0;
    elarisC02GraveMistakeRef.current.currentTime = 0;
    elarisC02RiverRef.current.currentTime = 0;

    // Play the selected audio
    audioRef.current.currentTime = 0; // Start from the beginning
    audioRef.current.play();
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audio.muted = !isMuted;
  };

  const handleInitialChoice01 = (choice) => {
    playAudio(elarisA00Ref); // Elaris first response to the A path
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'nextChoicesA01',  // Directly move to the next set of choices
    }));
  };

  const handleInitialChoice02 = (choice) => {
    playAudio(elarisB00Ref); // Elaris first response to the B path
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'nextChoicesB01',  // Directly move to the next set of choices
    }));
  };

  const handleInitialChoice03 = (choice) => {
    playAudio(elarisC00Ref); // Elaris first response to the B path
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'nextChoicesC01',  // Directly move to the next set of choices
    }));
  };

  const handleNextChoiceA01 = (choice) => {
    // Determine which audio to play based on the player's choice
    if (choice.text === "I’m not here to fight, but I won’t back down if you threaten me.") {
      playAudio(elarisA01BoldRef); // Play Bold response audio
    } else if (choice.text === "I have no quarrel with you, but I’m not afraid to defend myself.") {
      playAudio(elarisA01CourageRef); // Play Courageous response audio
    } else if (choice.text === "I’m lost and don’t know how I got here. I mean no harm.") {
      playAudio(elarisA01LostRef); // Play Courageous response audio
    }

    setState((prevState) => ({
      ...prevState,
      showFullBody: false,
      closeUpImage: choice.graphic,
      showCloseUp: true,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesA02',  // Proceed to the next set of choices
    }));
  };

  const handleNextChoiceA02 = (choice) => {
    // Determine which audio to play based on the player's choice
    if (choice.text === "Let’s talk this through. There’s no need for violence.") {
      playAudio(elarisA02TalkCheapRef); // Play Bold response audio
    } else if (choice.text === "I don’t want to fight, but I will if I must.") {
      playAudio(elarisA02PrepareRef); // Play Courageous response audio
    } else if (choice.text === "I just want to find a way out of this forest.") {
      playAudio(elarisA02EarnFreedomRef); // Play Courageous response audio
    }
    setState((prevState) => ({
      ...prevState,
      closeUpImage: choice.graphic,
      showCloseUp: true,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesA03',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceB01 = (choice) => {
    // Determine which audio to play based on the player's choice
    if (choice.text === "Step out cautiously, hands raised in a gesture of peace.") {
      playAudio(elarisB01PeaceRef); // Play Peaceful Gesture response audio
    } else if (choice.text === "Emerge from the bushes with a confident stride, trying to appear unbothered.") {
      playAudio(elarisB01WitsRef); // Play Courageous response audio
    } else if (choice.text === "Reveal yourself, tripping over your own feet and then offering an awkward, sheepish smile.") {
      playAudio(elarisB01VisitorRef); // Play Courageous response audio
    }
    setState((prevState) => ({
      ...prevState,
      showFullBody: false,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesB02',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceB02 = (choice) => {
    // Determine which audio to play based on the player's choice
    if (choice.text === "Carefully sidestep around her, keeping your distance.") {
      playAudio(elarisB02SincerityRef); // Play Peaceful Gesture response audio
    } else if (choice.text === "Nod respectfully and walk past her, keeping your eyes on the ground.") {
      playAudio(elarisB02RespectRef); // Play Peaceful Gesture response audio
    } else if (choice.text === "Walk past her with a forced, overly friendly smile, trying to appear non-threatening.") {
      playAudio(elarisB02WeaknessRef); // Play Peaceful Gesture response audio
    }
    setState((prevState) => ({
      ...prevState,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesB03',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceC01 = (choice) => {
    // Determine which audio to play based on the player's choice
    if (choice.text === "Slowly rise to your feet, hands raised in surrender.") {
      playAudio(elarisC01SilenceGameRef); // Play Peaceful Gesture response audio
    } else if (choice.text === "Quickly scramble to your feet, trying to appear unfazed.") {
      playAudio(elarisC01FoolRef); // Play Peaceful Gesture response audio
    } else if (choice.text === "Pretend to faint, hoping she’ll lose interest.") {
      playAudio(elarisC01FaintingRef); // Play Peaceful Gesture response audio
    }
    setState((prevState) => ({
      ...prevState,
      showFullBody: false,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesC02',  // Proceed to the next response stage
    }));
  };

  //  const handleNextChoiceC02 = (choice) => {
  // Determine which audio to play based on the player's choice
  //    if (choice.text === "Dash through the trees, aiming for an open clearing.") {
  //      playAudio(elarisC02ThinkEscapeRef); // Play Peaceful Gesture response audio
  //    } else if (choice.text === "Sprint along the path behind you, hoping it leads out of the forest and back home.") {
  //      playAudio(elarisC02GraveMistakeRef); // Play Peaceful Gesture response audio
  //    } else if (choice.text === "Charge toward the river, you're a good swimmer. Right?") {
  //      playAudio(elarisC02RiverRef); // Play Peaceful Gesture response audio
  //    }
  //    setState((prevState) => ({
  //      ...prevState,
  //      closeUpImage: choice.graphic,
  //      elvenResponse: choice.response,
  //      sceneStage: 'nextChoicesC03',  // Proceed to the next response stage
  //    }));
  //  };

  const handleNextChoiceC02 = (choice) => {
    // Determine which audio to play based on the player's choice
    if (choice.text === "Dash through the trees, aiming for an open clearing.") {
      playAudio(elarisC02ThinkEscapeRef); // Play the corresponding audio
      setState((prevState) => ({
        ...prevState,
        closeUpImage: choice.graphic,
        elvenResponse: choice.response,
        sceneStage: 'C02EndChoicesA', // Leads to ElvenHunterPass01
      }));
    } else if (choice.text === "Sprint along the path behind you, hoping it leads out of the forest and back home.") {
      playAudio(elarisC02GraveMistakeRef); // Play the corresponding audio
      setState((prevState) => ({
        ...prevState,
        closeUpImage: choice.graphic,
        elvenResponse: choice.response,
        sceneStage: 'C02EndChoicesB', // Leads to ElvenHunterBattle01
      }));
    } else if (choice.text === "Charge toward the river, you're a good swimmer. Right?") {
      playAudio(elarisC02RiverRef); // Play the corresponding audio
      setState((prevState) => ({
        ...prevState,
        closeUpImage: choice.graphic,
        elvenResponse: choice.response,
        sceneStage: 'C02EndChoicesC', // Leads to ElvenHunterRiver01
      }));
    }
  };



  const getElvenHunterResponse01 = () => {
    switch (state.selectedChoice) {
      case 'standGround':
        return "You dare stand your ground in my forest? Speak swiftly, or your courage will meet my blade.";
      default:
        return "";
    }
  };

  const getElvenHunterResponse02 = () => {
    switch (state.selectedChoice) {
      case 'hide':
        return "Cowards hide in shadows, but you cannot. Reveal yourself, trespasser!";
      default:
        return "";
    }
  };

  const getElvenHunterResponse03 = () => {
    switch (state.selectedChoice) {
      case 'playDead':
        return "Feigning death will not save you, fool! Rise, or I'll cut you and your ruse down!";
      default:
        return "";
    }
  };

  const getNextChoicesA01 = () => [
    {
      text: "I’m not here to fight, but I won’t back down if you threaten me.",
      graphic: closeupFurrowedBrow,
      response: "Bold words from an intruder! I admire your resolve, but it won't spare you. Draw your blade and show me your skill!",
    },
    {
      text: "I have no quarrel with you, but I’m not afraid to defend myself.",
      graphic: closeupAnnoyed,
      response: "Courageous words, but courage alone won't save you. Prepare to fight!",
    },
    {
      text: "I’m lost and don’t know how I got here. I mean no harm.",
      graphic: closeupCautious,
      response: "Lost or not, you'll need to prove your intentions. Draw your weapon!",
    },
  ];

  const getNextChoicesA02 = () => [
    {
      text: "Let’s talk this through. There’s no need for violence.",
      graphic: closeupSkeptical,
      response: "Talk is cheap. You'll have to prove your intentions through action.",
    },
    {
      text: "I don’t want to fight, but I will if I must.",
      graphic: closeupDetermined,
      response: "Then prepare yourself, because in this forest strength is everything!",
    },
    {
      text: "I just want to find a way out of this forest.",
      graphic: closeupShockedSurprise,
      response: "You’ll have to earn your freedom! That means surviving a duel. Against me!",
    },
  ];

  const getNextChoicesB01 = () => [
    {
      text: "Step out cautiously, hands raised in a gesture of peace.",
      graphic: closeupCautious,
      response: "A peaceful gesture, but trust is not easily gained here in my forest. I'll allow you to pass, but just know I'm watching.",
    },
    {
      text: "Emerge from the bushes with a confident stride, trying to appear unbothered.",
      graphic: closeupFurrowedBrow,
      response: "Tsk! Keep your wits about you, or the forest will devour your bravado.",
    },
    {
      text: "Reveal yourself, tripping over your own feet and then offering an awkward, sheepish smile.",
      graphic: closeupShockedSurprise,
      response: "(Laughs) Perhaps this forest has found a visitor worth watching. You may pass, but don’t let your guard down.",
    },
  ];

  const getNextChoicesB02 = () => [
    {
      text: "Carefully sidestep around her, keeping your distance.",
      graphic: closeupFurrowedBrow,
      response: "I sense a sincerity in you. You may pass, as long as your heart remains true.",
    },
    {
      text: "Nod respectfully and walk past her, keeping your eyes on the ground.",
      graphic: closeupShockedSurprise,
      response: "Such respect is rare in these woods. You may pass, but remember, trust is earned.",
    },
    {
      text: "Walk past her with a forced, overly friendly smile, trying to appear non-threatening.",
      graphic: closeupFurrowedBrow,
      response: "Your smile betrays your nerves. Proceed, but don’t mistake kindness for weakness.",
    },
  ];

  const getNextChoicesC01 = () => [
    {
      text: "Slowly rise to your feet, hands raised in surrender.",
      graphic: closeupCautious,
      response: "You think silence is a shield?! I need answers, not games. What’s your purpose here?",
    },
    {
      text: "Quickly scramble to your feet, trying to appear unfazed.",
      graphic: closeupFurrowedBrow,
      response: "Do not take me for a fool! Who dares to enter my forest, and why?!",
    },
    {
      text: "Pretend to faint, hoping she’ll lose interest.",
      graphic: closeupAnnoyed,
      response: "You can't be serious! Fainting?! By the gods.. I should cut you down and save this world from your stupidity!",
    },
  ];

  const getNextChoicesC02 = () => [
    {
      text: "Dash through the trees, aiming for an open clearing.",
      graphic: closeupFurrowedBrow,
      response: "Think you can escape? I will catch you, and there will be no mercy!",
    },
    {
      text: "Sprint along the path behind you, hoping it leads out of the forest and back home.",
      graphic: closeupDetermined,
      response: "This forest is my fortress! And you've made a grave mistake! You won't leave it alive!.",
    },
    {
      text: "Charge toward the river, you're a good swimmer. Right?",
      graphic: closeupAnnoyed,
      response: "Ugh! Think the river will save you?! It will deliver you straight to your death, by my hands!",
    }

  ];



  return (
    <div className="game-canvas-container">
      <div className="game-canvas" style={{ backgroundImage: `url(${forestBackground})` }}>
        {/* Audio Elements */}
        <audio ref={backgroundMusicRef} src={elvishFantasyVocalsLoop} loop autoPlay />
        <audio ref={narrationRef} src={narration01} />
        <audio ref={elarisA00Ref} src={elarisA00} />
        <audio ref={elarisB00Ref} src={elarisB00} />
        <audio ref={elarisC00Ref} src={elarisC00} />
        {/* Audio Controls */}
        <div className="audio-controls-container">
          <div className="audio-controls">
            <div className="audio-control">
              <img src={isMuted ? volOffIcon : volMaxIcon} alt="Toggle Mute" className="icon-white" onClick={toggleMute} />
              <span className="audio-button-label">On/Off</span>
            </div>
          </div>
        </div>



        {/* Initial Choices */}
        {state.sceneStage === 'initial' && (
          <DialogueContainer text="You've landed in a beautiful forest. You're not sure how you arrived, but you hear the sounds of someone, or something, approaching. What do you do?">
            <ChoicesContainer
              choices={[
                { text: 'Stand your ground, whoever or whatever it is doesn’t stand a chance against you (Intimidate Check DC 15).', action: handleInitialChoice1 },
                { text: 'Hide in the nearest bush. Whatever approaches sounds dangerous! (Stealth Check DC 12)', action: handleInitialChoice2 },
                { text: 'Play dead, you\'ll surely fool whatever approaches. (Deception Check DC 18)', action: handleInitialChoice3 },
              ]}
            />
          </DialogueContainer>
        )}

                {/* Show Dice Roller if a check is needed */}
                {showDiceRoller && (
          <DiceRoller onRoll={handleDiceRoll} successThreshold={diceCheckInfo.dc} />
        )}

        {/* Full-Body Image with Dialogue for A01 */}
        {state.sceneStage === 'nextChoicesA01' && (
          <>
            <img src={elvenHunterFullBody} alt="Elven Hunter Full Body" className="full-body" />
            <DialogueContainer text={getElvenHunterResponse01()}>
              <ChoicesContainer choices={getNextChoicesA01().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceA01(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Full-Body Image with Dialogue for B01 */}
        {state.sceneStage === 'nextChoicesB01' && (
          <>
            <img src={elvenHunterFullBody} alt="Elven Hunter Full Body" className="full-body" />
            <DialogueContainer text={getElvenHunterResponse02()}>
              <ChoicesContainer choices={getNextChoicesB01().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceB01(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Full-Body Image with Dialogue for C01 */}
        {state.sceneStage === 'nextChoicesC01' && (
          <>
            <img src={elvenHunterFullBody} alt="Elven Hunter Full Body" className="full-body" />
            <DialogueContainer text={getElvenHunterResponse03()}>
              <ChoicesContainer choices={getNextChoicesC01().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceC01(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Display Elven Hunter's response for A02 */}
        {state.sceneStage === 'nextChoicesA02' && (
          <>
            <img src={state.closeUpImage} alt="Elven Hunter Close Up" className="response-image" />
            <DialogueContainer text={state.elvenResponse}>
              <ChoicesContainer choices={getNextChoicesA02().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceA02(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Display Elven Hunter's response for B02 */}
        {state.sceneStage === 'nextChoicesB02' && (
          <>
            <img src={state.closeUpImage} alt="Elven Hunter Close Up" className="response-image" />
            <DialogueContainer text={state.elvenResponse}>
              <ChoicesContainer choices={getNextChoicesB02().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceB02(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Display Elven Hunter's response for C02 */}
        {state.sceneStage === 'nextChoicesC02' && (
          <>
            <img src={state.closeUpImage} alt="Elven Hunter Close Up" className="response-image" />
            <DialogueContainer text={state.elvenResponse}>
              <ChoicesContainer choices={getNextChoicesC02().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceC02(choice),
              }))} />
            </DialogueContainer>
          </>
        )}


        {/* Display Elven Hunter's response for A03 */}
        {state.sceneStage === 'nextChoicesA03' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              {/* Remove ChoicesContainer since there should be no more choices */}
              <button
                className="next-button"
                onClick={() => {
                  setState({ ...state, sceneStage: 'nextChoicesA03' });
                  navigate('/story/elvenhunter/battle01');  // Navigate to the battle scene
                }}
              >
                Next
              </button>
            </DialogueContainer>
            {/* Add the battle pose image centered */}
            <div className="battle-pose-container">
              <img src={fightEndingScene01} alt="Battle Pose" className="battle-pose-image" />
            </div>
          </>
        )}

        {/* Display Elven Hunter's response for B03 */}
        {state.sceneStage === 'nextChoicesB03' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              {/* Remove ChoicesContainer since there should be no more choices */}
              <button
                className="next-button"
                onClick={() => {
                  setState({ ...state, sceneStage: 'nextChoicesB03' });
                  navigate('/story/elvenhunter/pass01');  // Navigate to the battle scene
                }}
              >
                Next
              </button>
            </DialogueContainer>
            {/* Add the pass pose image */}
            <div className="pass-pose-container">
              <img src={passEndingScene01} alt="Pass Pose" className="pass-pose-image" />
            </div>
          </>
        )}

        {/* Display Elven Hunter's response for C03 */}
        {state.sceneStage === 'C02EndChoicesA' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              <button
                className="next-button"
                onClick={() => {
                  navigate('/story/elvenhunter/pass01');  // Navigate to the ElvenHunterPass01 page
                }}
              >
                Next
              </button>
            </DialogueContainer>
                        {/* Add the pass pose image */}
                        <div className="chase-pose-container">
              <img src={chaseEndingScene01} alt="Chase Pose" className="chase-pose-image" />
            </div>
          </>
        )}

        {state.sceneStage === 'C02EndChoicesB' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              <button
                className="next-button"
                onClick={() => {
                  navigate('/story/elvenhunter/battle01');  // Navigate to the ElvenHunterBattle01 page
                }}
              >
                Next
              </button>
            </DialogueContainer>
                        {/* Add the pass pose image */}
                        <div className="chase-pose-container">
              <img src={chaseEndingScene01} alt="Chase Pose" className="chase-pose-image" />
            </div>
          </>
        )}

        {state.sceneStage === 'C02EndChoicesC' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              <button
                className="next-button"
                onClick={() => {
                  navigate('/story/elvenhunter/river01');  // Navigate to the new ElvenHunterRiver01 page
                }}
              >
                Next
              </button>
            </DialogueContainer>
                        {/* Add the pass pose image */}
                        <div className="chase-pose-container">
              <img src={chaseEndingScene01} alt="Chase Pose" className="chase-pose-image" />
            </div>
          </>
        )}

      </div>
    </div>
  );
}


export default ElvenHunterIntro;
