import React from 'react';
import { useNavigate } from 'react-router-dom';
import elvenHunterThumbnail from '../assets/images/dolls/ElvenHunter/ElvenHunter-thumbnail.png';
import cyberHackerThumbnail from '../assets/images/dolls/CyberHacker/CyberHacker-thumbnail.png';
import TwoBThumbnail from '../assets/images/dolls/2B/2B-thumbnail.png'
import watermark from '../assets/images/AI-Watermark.png';
import '../App.css';

const FreeDollSelectionPage = () => {
  const navigate = useNavigate();

  const handleSelectElvenHunter = () => {
    navigate('/doll/elvenhunter-free');
  };

  const handleSelectCyberHacker = () => {
    navigate('/doll/cyberhacker-free');
  };

  const handleSelect2B = () => {
    navigate('/doll/2B-free');
  };

  // Navigate to the upgrade page
  const handleUpgrade = () => {
    navigate('/upgrade');
  };

  return (
    <div className="doll-selection-page">
      <h1>Select Your Doll</h1>

      {/* Display free dolls */}
      <div className="doll-grid">
      <div className="doll-item" onClick={handleSelect2B}>
          <img src={TwoBThumbnail} alt="2B" className="thumbnail" />
          <p>2B</p>
        </div>

        <div className="doll-item" onClick={handleSelectCyberHacker}>
          <img src={cyberHackerThumbnail} alt="Cyber Hacker" className="thumbnail" />
          <p>Cyber Hacker</p>
        </div>

        <div className="doll-item" onClick={handleSelectElvenHunter}>
          <img src={elvenHunterThumbnail} alt="Elven Hunter" className="thumbnail" />
          <p>Elven Hunter</p>
        </div>
      </div>

      {/* Footer Section */}
      <footer className="footer">

        {/* Upgrade message for the free version */}

        <div className="upgrade-section">
          <h2>Upgrade now to unlock even more dolls and outfits!</h2>
          <button onClick={handleUpgrade} className="upgrade-button">
            Upgrade
          </button>

        </div>
        <div>
          <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
            <img src={watermark} alt="Support me on Patreon" className="select-watermark" />
          </a>
          <p>
            <a href="https://www.patreon.com/AI_Pinups" target="_blank" rel="noopener noreferrer">
              Support me on Patreon by clicking here
            </a>
          </p>
        </div>

      </footer>
    </div>
  );

};

export default FreeDollSelectionPage;
