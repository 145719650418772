// src/pages/StorySelectionPage.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import elvenHunterFullBody from '../assets/game/ElvenHunter/scene01/ElvenHunterFullBody.png';
import '../App.css';

function StorySelectionPage() {
  const navigate = useNavigate();

  const handleElvenHunterStory = () => {
    navigate('/story/elvenhunter/scene01');
  };

  return (
    <div className="story-selection-page">
      <h1>Select a Story</h1>
      <div className="character-selection">
        <div className="character-item" onClick={handleElvenHunterStory}>
          <img
            src={elvenHunterFullBody}
            alt="Elven Hunter Full Body"
            className="elvenhunter-story-thumbnail"
          />
          <p>Elven Hunter Story</p>
        </div>
        {/* Other characters can be added here */}
      </div>
    </div>
  );
}

export default StorySelectionPage;
