import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
//import elvenHunterFullBody from '../../assets/game/ElvenHunter/scene01/ElvenHunterFullBody.png';
//import muddyPuddle from '../../assets/game/ElvenHunter/pass01/muddyPuddle.png'; // Image of Elaris after falling in mud
import muddyForestBackground from '../../assets/game/ElvenHunter/pass01/muddyForestBackground.jpg';
//import { useNavigate } from 'react-router-dom';
//Audio Controls
import volMaxIcon from '../../assets/icons/vol-max.svg';
//import volMinIcon from '../../assets/icons/vol-min.svg';
import volOffIcon from '../../assets/icons/vol-off.svg';
//Background Music
import medievalDanceNP from '../../assets/music/ElvenHunter/medievalDanceNP.mp3';
import '../../App.css';
import './ElvenHunterStory.css';

function ElvenHunterPass01() {
  const navigate = useNavigate();  // This line defines navigate
  const [state, setState] = useState({
    sceneStage: 'initial',
    selectedChoice: null,
    showFullBody: true,
    showCloseUp: false,
    closeUpImage: null,
    elvenResponse: '',
  });

  //const [audioVolume, setAudioVolume] = useState(0.2); // Initial volume at 60%
  const [isMuted, setIsMuted] = useState(false);
  const [audio] = useState(new Audio(medievalDanceNP));
  const backgroundMusicRef = useRef(null);

  useEffect(() => {
    if (isMuted) {
      backgroundMusicRef.current.volume = 0;
    } else {
      backgroundMusicRef.current.volume = 0.1; // adjust volume as needed
    }
  }, [isMuted]);

  const handlePassChoice = (choice) => {
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'passResponse',
      passResponse: choice.response,
    }));
  };

  const playAudio = (audioRef) => {
    // Stop all dialogues
    //narrationRef.current.pause();

    // Play the selected audio
    audioRef.current.currentTime = 0; // Start from the beginning
    audioRef.current.play();
  };

  const toggleMute = () => {
    setIsMuted(!isMuted);
    audio.muted = !isMuted;
  };

  const handleInitialChoice01 = (choice) => {
    //playAudio(elarisA00Ref); // Elaris first response to the A path
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'nextChoicesA01',  // Directly move to the next set of choices
    }));
  };

  const handleInitialChoice02 = (choice) => {
    //playAudio(elarisB00Ref); // Elaris first response to the B path
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'nextChoicesB01',  // Directly move to the next set of choices
    }));
  };

  const handleInitialChoice03 = (choice) => {
    //playAudio(elarisC00Ref); // Elaris first response to the B path
    setState((prevState) => ({
      ...prevState,
      selectedChoice: choice,
      sceneStage: 'nextChoicesC01',  // Directly move to the next set of choices
    }));
  };

  const handleNextChoiceA01 = (choice) => {
    //playAudio(elarisA00Ref); // Plays the specific Elaris dialogue  
    setState((prevState) => ({
      ...prevState,
      showFullBody: false,
      closeUpImage: choice.graphic,
      showCloseUp: true,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesA02',  // Proceed to the next set of choices
    }));
  };

  const handleNextChoiceA02 = (choice) => {
    setState((prevState) => ({
      ...prevState,
      closeUpImage: choice.graphic,
      showCloseUp: true,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesA03',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceB01 = (choice) => {
    //playAudio(elarisB00Ref); // Plays the specific Elaris dialogue
    setState((prevState) => ({
      ...prevState,
      showFullBody: false,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesB02',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceB02 = (choice) => {
    setState((prevState) => ({
      ...prevState,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesB03',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceC01 = (choice) => {
    //playAudio(elarisC00Ref); // Plays the specific Elaris dialogue
    setState((prevState) => ({
      ...prevState,
      showFullBody: false,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesC02',  // Proceed to the next response stage
    }));
  };

  const handleNextChoiceC02 = (choice) => {
    setState((prevState) => ({
      ...prevState,
      closeUpImage: choice.graphic,
      elvenResponse: choice.response,
      sceneStage: 'nextChoicesC03',  // Proceed to the next response stage
    }));
  };


  const getElvenHunterResponse01 = () => {
    switch (state.selectedChoice) {
      case 'standGround':
        return "You dare stand your ground in my forest? Speak swiftly, or your courage will meet my blade.";
      default:
        return "";
    }
  };

  const getElvenHunterResponse02 = () => {
    switch (state.selectedChoice) {
      case 'hide':
        return "Cowards hide in shadows, but you cannot. Reveal yourself, trespasser!";
      default:
        return "";
    }
  };

  const getElvenHunterResponse03 = () => {
    switch (state.selectedChoice) {
      case 'playDead':
        return "Feigning death will not save you, fool. Rise, or I'll cut down the ruse with you in it.";
      default:
        return "";
    }
  };

  const getNextChoicesA01 = () => [
    {
      text: "I’m not here to fight, but I won’t back down if you threaten me.",
      //graphic: closeupDetermined,
      response: "Bold words from an intruder! I admire your resolve, but it won't spare you. Draw your blade and show me your skill!",
    },
    {
      text: "I have no quarrel with you, but I’m not afraid to defend myself.",
      //graphic: closeupSkeptical,
      response: "Courageous words, but courage alone won't save you. Prepare to fight!",
    },
    {
      text: "I’m lost and don’t know how I got here. I mean no harm.",
      //graphic: closeupCautious,
      response: "Lost or not, you'll need to prove your intentions. Draw your weapon!",
    },
  ];

  const getNextChoicesA02 = () => [
    {
      text: "Let’s talk this through. There’s no need for violence.",
      //graphic: closeupSkeptical,
      response: "Talk is cheap. You'll have to prove your intentions through action.",
    },
    {
      text: "I don’t want to fight, but I will if I must.",
      //graphic: closeupDetermined,
      response: "Then prepare yourself, because in this forest, strength is everything!",
    },
    {
      text: "I just want to find a way out of this forest.",
      //graphic: closeupShockedSurprise,
      response: "You’ll have to earn your freedom, and that means surviving our duel!",
    },
  ];

  const getNextChoicesB01 = () => [
    {
      text: "Step out cautiously, hands raised in a gesture of peace.",
      //graphic: closeupCautious,
      response: "Your actions speak of peace, but words alone won't earn my trust. I'll be watching you closely. Proceed carefully, and perhaps you'll leave this forest unharmed.",
    },
    {
      text: "Emerge from the bushes with a confident stride, trying to appear unbothered.",
      //graphic: closeupFurrowedBrow,
      response: "Confidence alone won't protect you here. Keep your wits about you, or the forest will devour your bravado.",
    },
    {
      text: "Attempt to sneak away, hoping she won’t notice you slipping through the underbrush.",
      //graphic: closeupAnnoyed,
      response: "You think you can slip away unnoticed? The forest sees all, and so do I. You may pass, but know this—one false step, and you’ll find out just how unforgiving I can be.",
    },
  ];

  const getNextChoicesB02 = () => [
    {
      text: "Carefully sidestep around her, keeping your distance.",
      //graphic: closeupFurrowedBrow,
      response: "Keeping your distance won't save you if your intentions are false. Remember, I'm always watching.",
    },
    {
      text: "Nod respectfully and walk past her, keeping your eyes on the ground.",
      //graphic: closeupShockedSurprise,
      response: "Lowering your eyes won't hide your intentions. Make sure your respect is genuine, or I'll see through your facade.",
    },
    {
      text: "Walk past her with a forced, overly friendly smile, trying to appear non-threatening",
      //graphic: closeupFurrowedBrow,
      response: "Your smile doesn't mask your nervousness, stranger. Keep your intentions pure, or you'll find this forest less forgiving than my patience.",
    },
  ];

  const getNextChoicesC01 = () => [
    {
      text: "Slowly rise to your feet, hands raised in surrender.",
      //graphic: closeupCautious,
      response: "You may be wise to surrender, but it will take more than raised hands to earn my trust. Proceed with caution, or you'll regret it.",
    },
    {
      text: "Quickly scramble to your feet, trying to appear unfazed.",
      //graphic: closeupFurrowedBrow,
      response: "Your attempt to brush it off is laughable. I suggest you tread carefully; this forest does not forgive the careless.",
    },
    {
      text: "Pretend to faint, hoping she’ll lose interest.",
      //graphic: closeupAnnoyed,
      response: "Fainting? You truly are a fool. Rise now, or I’ll make sure you never get up again.",
    },
  ];

  const getNextChoicesC02 = () => [
    {
      text: "Dash through the trees, aiming for an open clearing.",
      //graphic: closeupFurrowedBrow,
      response: "Running? You think speed will save you? We'll see how far you get.",
    },
    {
      text: "Sprint along the path behind you, hoping it leads out of the forest.",
      //graphic: closeupDetermined,
      response: "You can run, but this forest is mine. I'll be right behind you.",
    },
    {
      text: "Charge toward the river, you're a good swimmer. Right?",
      //graphic: closeupAnnoyed,
      response: "The river’s current won't carry you to safety. You're only delaying the inevitable.",
    }

  ];



  return (
    <div className="game-canvas-container">
      <div className="game-canvas" style={{ backgroundImage: `url(${muddyForestBackground})` }}>
        {/* Audio Elements */}
        <audio ref={backgroundMusicRef} src={medievalDanceNP} loop autoPlay />
        {/*<audio ref={narrationRef} src={narration01} />
        <audio ref={elarisA00Ref} src={elarisA00} />
        <audio ref={elarisB00Ref} src={elarisB00} />
        <audio ref={elarisC00Ref} src={elarisC00} />*/}
        {/* Audio Controls */}
        <div className="audio-controls-container">
          <div className="audio-controls">
            {/*Volume Contols - Unused */}
            {/*
    <div className="audio-control">
      <img src={volMinIcon} alt="Decrease Volume" className="icon-white" onClick={decreaseVolume} />
      <span className="audio-button-label">-</span>
    </div>
    <div className="audio-control">
      <img src={volMaxIcon} alt="Increase Volume" className="icon-white" onClick={increaseVolume} />
      <span className="audio-button-label">+</span>
    </div> */}
            <div className="audio-control">
              <img src={isMuted ? volOffIcon : volMaxIcon} alt="Toggle Mute" className="icon-white" onClick={toggleMute} />
              <span className="audio-button-label">On/Off</span>
            </div>
          </div>
        </div>



        {/* Initial Choices */}
        {state.sceneStage === 'initial' && (
          <DialogueContainer text="You've landed in a beautiful forest. You're not sure how you arrived, but you hear the sounds of someone approaching. What do you do?">
            <ChoicesContainer
              choices={[
                { text: 'Stand your ground, whoever or whatever it is doesn’t stand a chance against you.', action: () => handleInitialChoice01('standGround') },
                { text: 'Hide in the nearest bush. Whatever approaches sounds dangerous!', action: () => handleInitialChoice02('hide') },
                { text: 'Play dead, you\'ll surely fool whatever approaches.', action: () => handleInitialChoice03('playDead') },
              ]}
            />
          </DialogueContainer>
        )}

        {/* Full-Body Image with Dialogue for A01 */}
        {state.sceneStage === 'nextChoicesA01' && (
          <>
            {/*<img src={elvenHunterFullBody} alt="Elven Hunter Full Body" className="full-body" /> */}
            <DialogueContainer text={getElvenHunterResponse01()}>
              <ChoicesContainer choices={getNextChoicesA01().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceA01(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Full-Body Image with Dialogue for B01 */}
        {state.sceneStage === 'nextChoicesB01' && (
          <>
            {/*<img src={elvenHunterFullBody} alt="Elven Hunter Full Body" className="full-body" /> */}
            <DialogueContainer text={getElvenHunterResponse02()}>
              <ChoicesContainer choices={getNextChoicesB01().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceB01(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Full-Body Image with Dialogue for C01 */}
        {state.sceneStage === 'nextChoicesC01' && (
          <>
            {/*<img src={elvenHunterFullBody} alt="Elven Hunter Full Body" className="full-body" /> */}
            <DialogueContainer text={getElvenHunterResponse03()}>
              <ChoicesContainer choices={getNextChoicesC01().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceC01(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Display Elven Hunter's response for A02 */}
        {state.sceneStage === 'nextChoicesA02' && (
          <>
            <img src={state.closeUpImage} alt="Elven Hunter Close Up" className="response-image" />
            <DialogueContainer text={state.elvenResponse}>
              <ChoicesContainer choices={getNextChoicesA02().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceA02(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Display Elven Hunter's response for B02 */}
        {state.sceneStage === 'nextChoicesB02' && (
          <>
            <img src={state.closeUpImage} alt="Elven Hunter Close Up" className="response-image" />
            <DialogueContainer text={state.elvenResponse}>
              <ChoicesContainer choices={getNextChoicesB02().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceB02(choice),
              }))} />
            </DialogueContainer>
          </>
        )}

        {/* Display Elven Hunter's response for C02 */}
        {state.sceneStage === 'nextChoicesC02' && (
          <>
            <img src={state.closeUpImage} alt="Elven Hunter Close Up" className="response-image" />
            <DialogueContainer text={state.elvenResponse}>
              <ChoicesContainer choices={getNextChoicesC02().map((choice, index) => ({
                text: choice.text,
                action: () => handleNextChoiceC02(choice),
              }))} />
            </DialogueContainer>
          </>
        )}


        {/* Display Elven Hunter's response for A03 */}
        {state.sceneStage === 'nextChoicesA03' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              {/* Remove ChoicesContainer since there should be no more choices */}
              <button
                className="next-button"
                onClick={() => {
                  setState({ ...state, sceneStage: 'nextChoicesA03' });
                  navigate('/story/elvenhunter/battle01');  // Navigate to the battle scene
                }}
              >
                Next
              </button>
            </DialogueContainer>
            {/* Add the battle pose image centered */}
            {/*<div className="battle-pose-container">
              <img src={fightEndingScene01} alt="Battle Pose" className="battle-pose-image" />
            </div>*/}
          </>
        )}

        {/* Display Elven Hunter's response for B03 */}
        {state.sceneStage === 'nextChoicesB03' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              {/* Remove ChoicesContainer since there should be no more choices */}
              <button
                className="next-button"
                onClick={() => {
                  setState({ ...state, sceneStage: 'nextChoicesB03' });
                  navigate('/story/elvenhunter/pass01');  // Navigate to the battle scene
                }}
              >
                Next
              </button>
            </DialogueContainer>
            {/* Add the pass pose image */}
            {/*<div className="pass-pose-container">
              <img src={passEndingScene01} alt="Pass Pose" className="pass-pose-image" />
            </div> */}
          </>
        )}

        {/* Display Elven Hunter's response for C03 */}
        {state.sceneStage === 'nextChoicesC03' && (
          <>
            <DialogueContainer text={state.elvenResponse}>
              {/* Remove ChoicesContainer since there should be no more choices */}
              <button
                className="next-button"
                onClick={() => {
                  setState({ ...state, sceneStage: 'nextChoicesC03' });
                  navigate('/story/elvenhunter/chase01');  // Navigate to the chase scene
                }}
              >
                Next
              </button>
            </DialogueContainer>
            {/* Add the pass pose image */}
            {/*<div className="chase-pose-container">
              <img src={chaseEndingScene01} alt="Chase Pose" className="chase-pose-image" />
            </div> */}
          </>
        )}
      </div>
    </div>
  );
}

const DialogueContainer = ({ text, children }) => (
  <div className="dialogue-container elvenhunter-dialogue">
    <p className="dialogue-text">{text}</p>
    {children}
  </div>
);

const ChoicesContainer = ({ choices }) => (
  <div className="choices-container">
    {choices.map((choice, index) => (
      <button key={index} onClick={choice.action}>
        {choice.text}
      </button>
    ))}
  </div>
);

export default ElvenHunterPass01;
