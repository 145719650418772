import React, { useState, useEffect } from 'react';

const HPComponent = ({ maxHP, loseHP, onDeath }) => {
  const [currentHP, setCurrentHP] = useState(maxHP);

  useEffect(() => {
    if (loseHP) {
      setCurrentHP(prevHP => {
        const newHP = prevHP - loseHP;
        if (newHP <= 0) {
          onDeath();
        }
        return newHP;
      });
    }
  }, [loseHP, onDeath]);

  useEffect(() => {
    if (loseHP <= 0) {
      onDeath(); // Call the onDeath function when HP reaches 0
    }
  }, [loseHP, onDeath]);
  

  return (
    <div className="hp-container">
      {Array.from({ length: maxHP }).map((_, index) => (
        <span key={index} className={`heart ${index < currentHP ? 'full' : 'empty'}`}>❤️</span>
      ))}
    </div>
  );
};

export default HPComponent;
