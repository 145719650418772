// utils/fetchUserData.js
const fetchUserData = async () => {
    try {
      const response = await fetch('https://api.ai-pinups.io/api/user', {
        method: 'GET',
        credentials: 'include',
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch user data: ${response.statusText}`);
      }
  
      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error fetching user data:', error);
      return null;
    }
  };
  
  export default fetchUserData;
  