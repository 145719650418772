import React, { useState } from 'react';

const ChoicesContainer = ({ choices }) => (
    <div className="choices-container">
      {choices.map((choice, index) => (
        <button key={index} onClick={choice.action}>
          {choice.text}
        </button>
      ))}
    </div>
  );

  export default ChoicesContainer;